import React from "react";
import "../../css/Footer.css";

const Footer = () => {
  return (
    <main
      className="background_main"
      style={{
        position: "relative",
        color: "#ffff",
        backgroundColor: "#f3f2f2",
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${"https://res.cloudinary.com/dqscjbzij/image/upload/v1701240878/Review_Backgorund_-min_viu6b9.jpg"})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
      }}
    >
      <div className="container">
        <div className="footer__container">
          <h3 className="component_heading">Let's Talk</h3>
        </div>
        <div className="row footer__row">
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <div className="footer_area">
              <div className="footer_heading">
                <h5 className="footer_h5 text-center">About</h5>
                <p className="footer_paragraph">
                  Professionally actualize distributed experiences and focused
                  content. Synergistically syndicate high.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <div className="footer_area">
              <div className="footer_heading">
                <h5 className="footer_h5 text-center">Contact Us</h5>
                <ul className="footer__unorderlink">
                  <li className="footer__list">
                    <a
                      href="mailto:dishachhabrasep@gmail.com"
                      className="disha_contact"
                    >
                      dishachhabrasep@gmail.com
                    </a>
                  </li>
                  <li className="footer__list">
                    <a href="tel:+916397305103" className="disha_contact">
                      +91 6397305103
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <div className="footer_area">
              <div className="footer_heading">
                <h5 className="footer_h5 text-center">Quick Links</h5>
                <ul className="footer__unorderlink">
                  <li className="footer__list_style">
                    <a href="#about-me">About Me</a>
                  </li>
                  <li className="footer__list_style">
                    <a href="#my-portfolio">Portfolio</a>
                  </li>
                  <li className="footer__list_style">
                    <a href="#contact-me">Contact Me</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <div className="footer_area">
              <div className="footer_heading">
                <h5 className="footer_h5 text-center style">Follow</h5>
                <ul className="footer__unorderlink">
                  <li className="footer__list_style">
                    <a
                      href="https://www.linkedin.com/in/disha-chhabra"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Linkedin
                    </a>
                  </li>
                  <li className="footer__list_style">
                    <a
                      href="https://www.behance.net/DISHACHHABRA"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Behance
                    </a>
                  </li>
                  <li className="footer__list_style">
                    <a
                      href="https://instagram.com/3d_vogue?igshid=OGQ5ZDc2ODk2ZA=="
                      target="_blank"
                      rel="noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copy_right_by_me">
          <p className="copyright">
            @{new Date().getFullYear()} CLO 3D Fashion designer, Inc. All Rights
            Reserved.
          </p>
        </div>
      </div>
    </main>
  );
};

export default Footer;
