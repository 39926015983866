import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../css/Navbar.css";
import logo from "../../assets/images/Logo 2.png";

const ReactNavbar2 = () => {
  const [activeLink, setActiveLink] = useState("Home");
  const [activeBackground, setActiveBackground] = useState(false);

  const handleNavLinkClick = (linkText) => {
    setActiveLink(linkText);
  };

  // Add a scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setActiveBackground(true);
      } else {
        setActiveBackground(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <nav
        className={`top_nav fixed-top navbar navbar-expand-lg ${
          activeBackground ? "active" : ""
        }`}
      >
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="Logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto mb-2 mb-lg-0">
              <li className="list">
                <Link
                  className={activeLink === "Home" ? "active" : ""}
                  style={{ color: "white" }}
                  to="/"
                  onClick={() => handleNavLinkClick("Home")}
                >
                  Home
                </Link>
              </li>
              <li className="list navname">
                <a
                  className={activeLink === "About Me" ? "active" : ""}
                  href="#about-me"
                  onClick={() => handleNavLinkClick("About Me")}
                >
                  About Me
                </a>
              </li>
              <li className="list navname">
                <a
                  className={activeLink === "Portfolio" ? "active" : ""}
                  href="#my-portfolio"
                  onClick={() => handleNavLinkClick("Portfolio")}
                >
                  Portfolio
                </a>
              </li>
              <li className="list">
                <a
                  className={activeLink === "Contact Me" ? "active" : ""}
                  href="#contact-me"
                  onClick={() => handleNavLinkClick("Contact Me")}
                >
                  Contact Me
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default ReactNavbar2;
