import React from "react";
import { Link } from "react-router-dom";
import photoShopImage from "../../assets/images/photoshop logo.png";
import cloImage from "../../assets/images/Clo 3d logo.png";
import illustratorImage from "../../assets/images/illustrator logo.png";
import myprofile from "../../assets/images/p2.jpg";
import "../../css/About.css";

const About = () => {
  return (
    <main
      className="background_main"
      style={{
        position: "relative",
        color: "#fff",
        backgroundColor: "#f3f2f2",
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${myprofile})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
      }}
    >
      <div className="container section_container">
        <div className="row portfolio__cards">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="left__container__content">
              <span className="my_story">My Story</span>
              <h3 className="component_heading spacing">
                Hello! There I am Disha <br /> Chhabra, CLO 3D Fashion <br />
                Designer.
              </h3>
            </div>
            <div className="row software-tool-row">
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-xs-12">
                <img
                  src={photoShopImage}
                  alt="photoshopeimg"
                  className="toolspace"
                />
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-xs-12">
                <img src={cloImage} alt="cloimg" className="toolspace clo3d" />
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-3 col-xs-12">
                <img
                  src={illustratorImage}
                  alt="illustratorimg"
                  className="toolspace"
                />
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="right__container__content">
              <div className="left__container__content">
                <p className="component_paragraph">
                  Hello, I'm Disha Chhabra, a CLO 3D designer with a passion for
                  bringing creative visions to life. With a solid foundation in
                  textile designing and one year of hands-on experience, I've
                  honed my skills and am now eagerly seeking new opportunities
                  to further immerse myself in the dynamic world of 3D design.
                </p>

                <p className="component_paragraph">
                  I thrive on the creative process, and my dedication to
                  producing high-quality work is unwavering. Whether it's
                  exploring new design concepts, perfecting intricate details,
                  or staying on the cutting edge of industry trends, I am always
                  eager to embrace fresh challenges and push the boundaries of
                  my capabilities.
                </p>
              </div>
              <div className="about__button">
                <Link to="/about-me" className="aboutLink">
                  About Me
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default About;
