import React, { useEffect, useState } from "react";
// import staticVideo from "../../assets/videos/shirt top_0.mp4";
import { Card, CardBody } from "@chakra-ui/react";
import Slider from "react-slick";
import ReactNavbar2 from "../../components/Navbar/ReactNavbar2";
// import specImage from "../../assets/images/SS 1.1.jpg";
import Client from "../../Client";
import { useParams } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectCreative } from "swiper/modules";
import SwiperCore from "swiper";
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/navigation";

SwiperCore.use([Navigation, EffectCreative]);

function isVideo(url) {
  if (typeof url === "string") {
    // Check if the URL contains a video-related keyword (you can add more if needed)
    const videoKeywords = [
      "mp4",
      "avi",
      "mov",
      "wmv",
      "mkv",
      "flv",
      "webm",
      "m4v",
    ];
    return videoKeywords.some((keyword) => url.toLowerCase().includes(keyword));
  }
  return false; // Return false if url is not a string or is undefined
}

const PortfolioDetailPage = () => {
  const [portfolioData, setPortfolioData] = useState([]);
  const [detailPageImage, setDetailPageImage] = useState([]);
  const [inspirationImage, setInspirationImage] = useState([]);
  const [moodImage, setMoodImage] = useState([]);
  const [portfolioImagesInfo, setPortfolioImagesInfo] = useState([]);
  const [specSheetLink, setSpecSheetLink] = useState();
  const [specSheetImage, setSpecSheetImage] = useState([]);

  const { slug } = useParams();
  console.log("slug is :", slug);

  // const portfolioImagesInfo = [
  //   {
  //     image: staticVideo,
  //   },
  //   {
  //     image: staticVideo,
  //   },
  //   {
  //     image: staticVideo,
  //   },
  //   {
  //     image: staticVideo,
  //   },
  //   {
  //     image: staticVideo,
  //   },
  //   {
  //     image: staticVideo,
  //   },
  // ];

  // const specInfo = [
  //   {
  //     image: specImage,
  //   },
  //   {
  //     image: specImage,
  //   },
  //   {
  //     image: specImage,
  //   },
  //   {
  //     image: specImage,
  //   },
  //   {
  //     image: specImage,
  //   },
  //   {
  //     image: specImage,
  //   },
  // ];

  useEffect(() => {
    Client.fetch(
      `*[slug.current == "${slug}"] {
      title,
      inspirationTitle,
      moodTitle,
      detailDescription,
      inspirationDescription,
      moodDescription,
      spectitle,
      specsheetlink,
      slug,

      mainImage{
        asset ->{
          _id,
          url
        },
        alt, 
       },
       inspirationImage{
        asset ->{
          _id,
          url
        },
        alt, 
       },
       moodImage{
        asset ->{
          _id,
          url
        },
        alt, 
       },
       mediaone { 
        asset -> {
          _id,
          url
        },
        alt
      },
       mediatwo { 
        asset -> {
          _id,
          url
        },
        alt
      },
      mediathree { 
        asset -> {
          _id,
          url
        },
        alt
      },
      mediafour { 
        asset -> {
          _id,
          url
        },
        alt
      },
      mediafive { 
        asset -> {
          _id,
          url
        },
        alt
      },
      mediasix { 
        asset -> {
          _id,
          url
        },
        alt
      },
      mediaseven { 
        asset -> {
          _id,
          url
        },
        alt
      },
      mediaeight { 
        asset -> {
          _id,
          url
        },
        alt
      },
      specsheet { 
        asset -> {
          _id,
          url
        },
        alt
      },

    }`
    ).then((data) => {
      console.log("data is :", data[0]);
      setPortfolioData(data[0]);
      setDetailPageImage(data[0].mainImage.asset.url);
      setInspirationImage(data[0].inspirationImage.asset.url);
      setMoodImage(data[0].moodImage.asset.url);
      setSpecSheetLink(data[0].specsheetlink);
      setSpecSheetImage(data[0].specsheet.asset.url);

      const mediaAssets = [
        data[0].mediaone?.asset?.url,
        data[0].mediatwo?.asset?.url,
        data[0].mediathree?.asset?.url,
        data[0].mediafour?.asset?.url,
        data[0].mediafive?.asset?.url,
        data[0].mediasix?.asset?.url,
        data[0].mediaseven?.asset?.url,
        data[0].mediaeight?.asset?.url,
      ];
      setPortfolioImagesInfo(mediaAssets);
    });
  }, []);

  const handleScroll = () => {
    const targetHeight = 0.9 * window.innerHeight; // Adjust the factor as needed
    window.scrollTo({
      top: targetHeight,
      behavior: "smooth",
    });
  };

  return (
    <main className="background_main">
      <ReactNavbar2 />
      <div className="detail__page__image">
        <img
          src={detailPageImage}
          alt="about_me_image"
          className="about_me_image"
        />
        {/* <div className="about_me">
          <p className="about_me_text projectdetail">{portfolioData.title}</p>
        </div> */}
        <p className="scrollDown__about" onClick={handleScroll}>
          <div className="arrow2"></div>
          <div className="arrow2"></div>
          <div className="arrow2"></div>
        </p>
      </div>

      <div className="portfolio__detail__page">
        <div className="container section_container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="left_text_detail pt-4">
                <h3 className="portfolio_detail_heading pt-2">
                  {portfolioData.inspirationTitle}
                </h3>
                <p className="portfolio_detail_para">
                  {portfolioData.inspirationDescription}
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="right_text_detail pt-4">
                {/* <p className="portfolio_detail_para pt-2">
                  {portfolioData.detailDescription}
                </p> */}

                <div className="portfolio__image">
                  <img
                    className="img_portfolio"
                    src={inspirationImage}
                    alt="portfolioImg"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-4">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="left_text_detail pt-4">
                <h3 className="portfolio_detail_heading pt-2">
                  {portfolioData.moodTitle}
                </h3>
                <p className="portfolio_detail_para">
                  {portfolioData.moodDescription}
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="right_text_detail pt-4">
                <div className="portfolio__image">
                  <img
                    className="img_portfolio"
                    src={moodImage}
                    alt="portfolioImg"
                  />
                </div>
              </div>
            </div>
          </div>
          <Slider
            dots={true}
            slidesToShow={4}
            slidesToScroll={1}
            autoplay={true}
            autoplaySpeed={3000}
            responsive={[
              {
                breakpoint: 640, // Phones
                settings: {
                  slidesToShow: 1,
                },
              },
              {
                breakpoint: 1200, // Laptops
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 1400, // LG Laptops
                settings: {
                  slidesToShow: 3,
                },
              },
            ]}
          >
            {portfolioImagesInfo &&
              portfolioImagesInfo
                .filter((url) => url) // Filter out undefined or empty URLs
                .map((url, index) => (
                  <div className="container section_container" key={index}>
                    <Card className="portfolio_detail_cards">
                      <CardBody>
                        {isVideo(url) ? (
                          <video
                            autoPlay
                            muted
                            loop
                            width="100%"
                            height="auto"
                            type="video/mp4"
                          >
                            <source src={url} />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img src={url} alt={`Image ${index}`} />
                        )}
                      </CardBody>
                    </Card>
                  </div>
                ))}
          </Slider>
        </div>

        {/*...........................TEC SPECS..........................*/}

        <div className="tec_sepcs_area">
          <div className="spec_heading">
            <h3 className="portfolio_detail_heading spec-text">
              {portfolioData.spectitle}
            </h3>
            <p className="spec_span">
              Click below image to see more like these.
            </p>
          </div>
          <a href={specSheetLink} target="_blank" rel="noreferrer">
            <img src={specSheetImage} alt="" className="spec-sheet-image" />
          </a>
          {/* <Swiper
            grabCursor={true}
            dots={true}
            effect={"creative"}
            creativeEffect={{
              prev: {
                shadow: true,
                translate: [0, 0, -400],
              },
              next: {
                translate: ["100%", 0, 0],
              },
            }}
            modules={[EffectCreative]}
            navigation={true}
            className="mySwiper"
          >
            {specSheetImage &&
              specSheetImage.map((res, index) => {
                return (
                  <>
                    <SwiperSlide>
                      <img src={specSheetImage} alt="specimages" />
                    </SwiperSlide>
                  </>
                );
              })}
          </Swiper> */}
        </div>
      </div>
    </main>
  );
};

export default PortfolioDetailPage;
