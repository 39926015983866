import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Image,
  Stack,
  Heading,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Client from "../../Client";
import "../../css/Portfolio.css";

const NewPortfolio = () => {
  const [portfolioData, setPortfolioData] = useState([]);

  useEffect(() => {
    Client.fetch(
      `*[_type == "portfolio"]{
       title,
       slug,
       description,
       mainImage{
         asset ->{
           _id,
           url
         },
         alt, 
        },
    }`
    )
      .then((data) => {
        setPortfolioData(data);
      })
      .catch(console.error);
  }, []);

  return (
    <main className="background_main">
      <div className="container section_container">
        <span className="my_story">Portfolio</span>
        <h3 className="component_heading spacing">Selected Work</h3>
        <div className="portfolio__new__card">
          {portfolioData &&
            portfolioData.map((data, index) => {
              return (
                <div className="new_cards" key={index}>
                  <Card className="new_card_height">
                    <CardBody>
                      <Image
                        src={data.mainImage.asset.url}
                        alt="Green double couch with wooden legs"
                        borderRadius="lg"
                        className="new_card_image"
                      />
                      <Stack className="new_card_space">
                        <Heading size="md" className="new_card_heading">
                          {data.title}
                        </Heading>
                        <Text>{data.description}</Text>
                      </Stack>
                    </CardBody>
                    <CardFooter>
                      <div className="about__button new_port_button">
                        <Link
                          to={`/view-project/${data.slug.current}`}
                          className="aboutLink new_card_link"
                        >
                          View Project
                        </Link>
                      </div>
                    </CardFooter>
                  </Card>
                </div>
              );
            })}
        </div>
      </div>
    </main>
  );
};

export default NewPortfolio;
