import { Box } from "@chakra-ui/react";
import React from "react";
import About from "../../components/About/About";
import Contact from "../../components/Contact/Contact";
import ReactNavbar from "../../components/Navbar/ReactNavbar";
import NewPortfolio from "../../components/Portfolios/NewPortfolio";
import Reviews from "../../components/Reviews/Reviews";
import LandingPage from "./LandingPage";

const Home = ({ API_URL }) => {
  return (
    <Box>
      <ReactNavbar />
      <LandingPage />
      <div id="about-me">
        <About />
      </div>
      <div id="my-portfolio">
        <NewPortfolio />
      </div>
      <Reviews API_URL={API_URL} />
      <div id="contact-me">
        <Contact API_URL={API_URL} />
      </div>
    </Box>
  );
};

export default Home;
