import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import $ from "jquery";
import Home from "./pages/Home/Home";
import Footer from "./components/Footer/Footer";
import AboutDetailPage from "./pages/DetailPages/AboutDetailPage";
import PortfolioDetailPage from "./pages/DetailPages/PortfolioDetailPage";
import { useEffect } from "react";
import "./css/backtotop.css";

const API_URL = "https://clodb-1-u5127059.deta.app";

function App() {
  useEffect(() => {
    // Back to top button functionality
    const btn = $("#scrolltotopbutton");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 400) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "400");
    });
  }, []);

  return (
    <div className="App">
      <Router>
        <button id="scrolltotopbutton" className="back-to-top"></button>
        <Routes>
          <Route path="/" element={<Home API_URL={API_URL} />} />
          <Route path="/about-me" element={<AboutDetailPage />} />
          <Route path="/view-project/:slug" element={<PortfolioDetailPage />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
