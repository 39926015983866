import React from "react";
import aboutMeImage from "../../assets/images/p2.jpg";
import myImage from "../../assets/images/disha2.jpg";
import photoShopImage from "../../assets/images/photoshop logo.png";
import cloImage from "../../assets/images/Clo 3d logo.png";
import illustratorImage from "../../assets/images/illustrator logo.png";
import ReactNavbar2 from "../../components/Navbar/ReactNavbar2";

const AboutDetailPage = () => {
  const handleScroll = () => {
    const targetHeight = 0.9 * window.innerHeight; // Adjust the factor as needed
    window.scrollTo({
      top: targetHeight,
      behavior: "smooth",
    });
  };

  const softwareImagesInfo = [
    {
      title: "Photoshop",
      image: photoShopImage,
      description:
        "Adobe Photoshop: A versatile image editing software trusted by professionals worldwide. It empowers creative minds to transform ideas into stunning visuals with precision and ease.",
    },
    {
      title: "CLO 3D",
      image: cloImage,
      description:
        "CLO 3D is a cutting-edge fashion design software,revolutionizing the industry with its realistic 3Dgarment simulations. It enables precise digital prototyping for the fashion world.",
    },
    {
      title: "Illustrator",
      image: illustratorImage,
      description:
        "Adobe Illustrator is a versatile vector graphics software used for creating stunning illustrations, logos, and designs with precision. Its robust tools empower creative professionals worldwide.",
    },
  ];

  return (
    <main className="background_main">
      <ReactNavbar2 />
      <div className="detail__page__image">
        <img
          src={aboutMeImage}
          alt="about_me_image"
          className="about_me_image"
        />
        <div className="about_me">
          <p className="about_me_text">About Me</p>
        </div>
        <p className="scrollDown__about" onClick={handleScroll}>
          <div className="arrow2"></div>
          <div className="arrow2"></div>
          <div className="arrow2"></div>
        </p>
      </div>

      <div className="container section_container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="left_about_detail_page">
              <p className="about__paragraph">
                Hello, I'm Disha Chhabra, a seasoned CLO 3D designer with a
                passion for bringing creative visions to life. With a solid
                foundation in textile designing and one year of hands-on
                experience, I've honed my skills and am now eagerly seeking new
                opportunities to further immerse myself in the dynamic world of
                3D design.
              </p>
              <p className="about__paragraph">
                I thrive on the creative process, and my dedication to producing
                high-quality work is unwavering. Whether it's exploring new
                design concepts, perfecting intricate details, or staying on the
                cutting edge of industry trends, I am always eager to embrace
                fresh challenges and push the boundaries of my capabilities.
              </p>
              <p className="about__paragraph">
                If you're searching for a passionate, hardworking, and creative
                CLO 3D designer who is dedicated to exceeding expectations and
                constantly evolving, I would welcome the opportunity to discuss
                how I can contribute to your team and help bring your design
                visions to reality.
              </p>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="right__container__content">
              <div className="component__image">
                <img
                  src={myImage}
                  alt="developerimage"
                  className="image_img img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          color: "#fff",
          backgroundColor: "#f3f2f2",
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${aboutMeImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="container section_container">
          <div className="row">
            {softwareImagesInfo &&
              softwareImagesInfo.map((res, index) => {
                return (
                  <div
                    className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12"
                    key={index}
                  >
                    <div className="software_image position-relative">
                      <img
                        src={res.image}
                        alt="clo_image"
                        className="software_img"
                      />
                      <p className="photoshop_name">{res.title}</p>
                      <p className="photoshop_paragraph">{res.description}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </main>
  );
};

export default AboutDetailPage;
