import axios from "axios";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "../../css/Review.css";

function Reviews({ API_URL }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    FetchingReviews();
  }, []);

  const FetchingReviews = async () => {
    try {
      await axios.get(`${API_URL}/api/fetchingreviews`).then((res) => {
        setReviews(res.data);
      });
    } catch (error) {
      console.log("error:", error);
    }
  };
  // const reviewInfo = [
  //   {
  //     description:
  //       "The invite cards were really mesmerizing and have permanently created a loving memory of our special day.The service and time accuracy is top notch and we'd recommend Ms. Disha Chhabra to anyone without any second thought.",
  //     name: "Mrs. Bhumika Arora and Mr. Tanuj Arora",
  //   },
  //   {
  //     description:
  //       "Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis justo.",
  //     name: "Doe",
  //   },
  //   {
  //     description:
  //       "Working with Ms. Disha Chhabra was a fantastic experience! She brought my fashion sketches and vision into 3D with exceptional skill and creativity. She delivered the project within the timeline with all the details and gave her full attention. I look forward to more collaborations in the future.",
  //     name: "Mr. Shivam Aggarwal",
  //   },
  //   {
  //     description:
  //       "Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis justo.",
  //     name: "Mr.JD",
  //   },
  // ];

  return (
    <main
      className="background_main"
      style={{
        position: "relative",
        color: "#000",
        backgroundColor: "#f3f2f2",
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${"https://res.cloudinary.com/dqscjbzij/image/upload/v1698735715/Review_Backgorund_vw-min_mnczwk.jpg"})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
      }}
    >
      <div className="container reviewarea">
        <span className="double_inverted_comma">"</span>
      </div>
      <div className="container review_container">
        <Slider {...settings}>
          {reviews &&
            reviews.map((res, index) => {
              return (
                <div className="review_area" key={index}>
                  <p className="component_paragraph review_desc">
                    {res.reviewdescriptions}
                  </p>
                  <span className="user_name">{res.clientname}</span>
                </div>
              );
            })}
        </Slider>
      </div>
    </main>
  );
}

export default Reviews;
