import React, { useState } from "react";
import { Button } from "@chakra-ui/react";
import pdfFile from "../../assets/Pdf/Resume  (1).pdf";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/Contact.css";
import axios from "axios";
import Loading from "../Loading";

const Contact = ({ API_URL }) => {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const Submit_Contact = async () => {
    setLoading(true);
    try {
      toast.dismiss();

      if (!fullname || !email || !description) {
        toast.warn("Please fill in all fields!");
        return;
      }
      const { data } = await axios.post(`${API_URL}/api/contact`, {
        fullname: fullname,
        email: email,
        description: description,
      });
      toast.success(data.message);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = () => {
    // Generate a random string of characters
    const randomString = Math.random().toString(36).substring(7);
    const timestamp = new Date().getTime();

    // Combine the timestamp and random string to create a unique file name
    const fileName = `${timestamp}_${randomString}.pdf`;

    // Create a virtual anchor element
    const link = document.createElement("a");
    link.href = pdfFile;
    link.download = fileName;
    link.click();

    // Clean up by removing the virtual anchor element
    link.remove();
  };

  return (
    <main className="background_main">
      {loading && <Loading />}
      <div className="container section_container">
        <div className="row portfolio__cards">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="left__container__content">
              <span className="my_story">Hello!</span>
              <h3 className="component_heading spacing">
                Did you like any of my work? <br />
                Wanna contact for <br />
                some project?
              </h3>

              <div className="about__button pt-5">
                <Button
                  colorScheme="blue"
                  variant="outline"
                  className="download_button"
                  onClick={handleDownload}
                >
                  Download Resume
                </Button>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="right__container__content">
              <div className="form_container">
                <div className="mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Full Name *
                  </label>
                  <input
                    type="text"
                    value={fullname}
                    onChange={(e) => setFullname(e.target.value)}
                    className="form-control"
                    id="exampleInputText1"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email Address *
                  </label>
                  <input
                    type="email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                  <div id="emailHelp" className="form-text">
                    We'll never share your email with anyone else.
                  </div>
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className="form-label"
                  >
                    Tell us about your project *
                  </label>
                  <textarea
                    className="form-control"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    id="exampleFormControlTextarea1"
                    rows="3"
                  ></textarea>
                </div>
                <div className="about__button">
                  <Button
                    colorScheme="blue"
                    variant="outline"
                    className="download_button"
                    onClick={Submit_Contact}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </main>
  );
};

export default Contact;
